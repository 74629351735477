import { setIsActiveList } from 'actions/ui';
import Icon from 'components/utils/Icon';
import Modal from 'components/utils/Modal';
import Tooltip from 'components/utils/Tooltip';
import useTranslation from 'hooks/useTranslation';
import { Link } from 'components/utils/Link';
import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/utils/Button';
import style from './sidebar.style';
import useResponsive from '../../hooks/useResponsive';
import { InlineIcon } from '@iconify/react';
import { useRouterWithLang } from 'hooks/useRouterWithLang';
import { getPlanName } from '../../tools/utils';

function Sidebar({ inactive, handleInactive }) {
  const { TRANSLATIONS, locale } = useTranslation();
  const dispatch = useDispatch();
  const PERMISSIONS = useSelector((state) => state.permissions);
  const { isMobile, isTablet } = useResponsive();
  const isSmallDevice = isMobile || isTablet;
  const router = useRouterWithLang();
  const catalogs = useSelector((state) => state?.user?.userCatalogs) || [];
  const isActiveList = useSelector((state) => state?.UI?.isActiveList);
  const user = useSelector((state) => state.user);
  const adminPlans = router.pathname === '/admin/plans';
  const adminTranslations = router.pathname === '/admin/translations';
  const adminUsers = router.pathname === '/admin/users';
  const adminReturns = router.pathname === '/admin/returns';
  const adminInvoices = router.pathname === '/admin/invoices';
  const adminBills = router.pathname === '/admin/bill';
  const isAdminSection =
    adminTranslations ||
    adminPlans ||
    adminUsers ||
    adminBills ||
    adminReturns ||
    adminInvoices;
  const { id, category } = router.query;
  const [isActiveAdmin, setIsActiveAdmin] = useState(
    isAdminSection ? true : false
  );
  const handleIsActiveAdmin = useCallback(() => {
    setIsActiveAdmin((prevState) => !prevState);
  }, []);
  const handleisActiveList = useCallback(
    (catalogId) => {
      if (id === catalogId) {
        dispatch(setIsActiveList(!isActiveList));
      } else {
        dispatch(setIsActiveList(true));
      }
    },
    [dispatch, id, isActiveList]
  );
  const canCreateList = useMemo(
    () =>
      !!(PERMISSIONS.CAN_HAVE_3_LISTS && catalogs?.length < 3) ||
      !!(PERMISSIONS.CAN_HAVE_2_LIST && catalogs?.length < 2) ||
      !!(PERMISSIONS.CAN_HAVE_1_LIST && catalogs?.length < 1) ||
      PERMISSIONS.IS_ADMIN,
    [
      PERMISSIONS.CAN_HAVE_1_LIST,
      PERMISSIONS.CAN_HAVE_2_LIST,
      PERMISSIONS.CAN_HAVE_3_LISTS,
      PERMISSIONS.IS_ADMIN,
      catalogs?.length,
    ]
  );

  const bannerLink = useMemo(
    () =>
      locale === 'it_IT'
        ? 'https://calendly.com/bdroppy-team/bdroppy-italiano'
        : locale === 'fr_FR'
        ? 'https://calendly.com/bdroppy-team/bdroppy-francais'
        : locale === 'es_ES'
        ? 'https://calendly.com/bdroppy-team/bdroppy-espanol'
        : 'https://calendly.com/bdroppy-team/bdroppy-english',
    [locale]
  );
  const [openModal, setOpenModal] = useState({
    index: -1,
    type: 'createlist',
    width: '',
    role: '',
  });
  const handleModal = useCallback(({ type, index, width, role }) => {
    setOpenModal({ type, index, width, role });
  }, []);
  const handleCreateList = useCallback(() => {
    if (canCreateList) {
      router.push('/channel/new');
    } else {
      handleModal({ type: 'createlist', index: 1, width: '400px', role: '' });
    }
  }, [canCreateList, handleModal, router]);

  const mapChannelService = (d) => {
    if (d.connections[0]) {
      if (d.connections[0].component === 'woocommercePlugin') {
        return 'Woocommerce';
      }
      if (d.connections[0].component === 'prestashopPlugin') {
        return 'Prestashop';
      }
      if (d.connections[0].component === 'SHOPIFYBRANDSSYNC') {
        return 'Shopify';
      }
      if (d.connections[0].component === 'shopifyPlugin') {
        return 'Shopify';
      }
      if (d.connections[0].component === 'ebay') {
        return 'Ebay';
      }
      if (d.connections[0].component === 'amazon') {
        return 'Amazon';
      }
      if (d.connections[0].component === 'WIX') {
        return 'Wix';
      }
      if (d.connections[0].component === 'SQUARESPACE') {
        return 'Squarespace';
      }
      if (d.connections[0].component === 'ECWID') {
        return 'Ecwid';
      }
      if (d.connections[0].component === 'SHOPIFY') {
        return 'Shopify';
      }
      if (d.connections[0].component === 'socialEcommerce') {
        return 'Wishlist';
      }
      if (d.connections[0].component === 'WOOCOMMERCE') {
        return 'Woocommerce';
      }
      if (d.connections[0].component === 'turnkey') {
        return 'Turnkey';
      }
    } else {
      return TRANSLATIONS.sidebar.newList;
    }
  };

  const hasShopifyConnection = catalogs.some(
    (catalog) =>
      catalog?.connections?.[0]?.component === 'SHOPIFY' ||
      catalog?.connections?.[0]?.component === 'turnkey'
  );

  const goToAidify = () => {
    window.open('https://www.aidify.me/', '_blank');
  };

  return (
    <>
      <Modal
        openModal={openModal?.type && openModal?.index >= 0}
        onClose={() => {
          handleModal({ type: null, index: -1, width: '0px', role: '' });
        }}
        title={
          user.catalogNumber > 1
            ? TRANSLATIONS.sidebar.modal.title3Lists
            : TRANSLATIONS.sidebar.modal.title
        }
      >
        <div className="d-f fd-c ai-c jc-sb g-20">
          {user?.catalogNumber > 1 ? (
            <>
              <div className="d-f ta-c fd-c g-10 mb-md">
                <p>{TRANSLATIONS.sidebar.modal.subTitle3Lists}</p>
              </div>
              <a href={bannerLink} target="_blank" rel="noreferrer">
                <Button
                  className={'primary medium'}
                  label={TRANSLATIONS.sidebar.modal.bookCall}
                  onClick={() =>
                    handleModal({
                      type: null,
                      index: -1,
                      width: '0px',
                      role: '',
                    })
                  }
                />
              </a>
            </>
          ) : (
            <>
              <div className="d-f ta-c fd-c g-10 mb-md">
                <p>{TRANSLATIONS.sidebar.modal.subTitle1List}</p>
              </div>
              <Link href="/pricing">
                <Button
                  className={'primary medium'}
                  label={TRANSLATIONS.sidebar.modal.upgrade}
                  onClick={() =>
                    handleModal({
                      type: null,
                      index: -1,
                      width: '0px',
                      role: '',
                    })
                  }
                />
              </Link>
            </>
          )}
        </div>
      </Modal>
      <div className={inactive ? 'overlay' : ''} onClick={handleInactive} />
      <aside className={`sidenav ${inactive ? ' inactive' : ' active'}`}>
        <div className={`sidenav__top-section relative`}>
          <div className={'d-f fd-c g-2'}>
            <div
              className="user-menu-title d-f fw-w ai-c g-10"
              onClick={() => {
                router.push('/profile/general');
              }}
            >
              <p>{user?.name}</p>
            </div>
            <div>
              <span
                className={`role-user 
              ${
                getPlanName(user?.roles[0]?.name) === 'Social' &&
                'bsocial__role'
              }
              ${
                getPlanName(user?.roles[0]?.name) === 'Plugin' && 'plugin__role'
              } 
              ${
                getPlanName(user?.roles[0]?.name) === 'Plugin +' &&
                'plugin_plus__role'
              }`}
              >
                {getPlanName(user?.roles[0]?.name)}
              </span>
            </div>
          </div>
          {isSmallDevice && (
            <div
              className="close-btn"
              onClick={handleInactive}
              style={{ cursor: 'pointer' }}
            >
              <Icon
                type="chevrons-left"
                style={{ fontSize: 22, color: 'var(--foreground)' }}
              />
            </div>
          )}
        </div>
        <div>
          <ul className="menu admin">
            {PERMISSIONS.IS_ADMIN && (
              <div className="admin-section" onClick={handleIsActiveAdmin}>
                <li className={isAdminSection ? 'active-name' : ''}>
                  <Icon type="admin_panel_settings" style={{ fontSize: 26 }} />
                  <a>{TRANSLATIONS.sidebar.admin}</a>
                  <span
                    className={
                      isActiveAdmin
                        ? 'icon-chevron-right active'
                        : 'icon-chevron-right'
                    }
                  />
                </li>
              </div>
            )}
            <div
              className={
                isActiveAdmin
                  ? 'dropdown-container active'
                  : 'dropdown-container none'
              }
            >
              <ul>
                <Link href="/admin/plans" passHref={true}>
                  <li className={adminPlans ? 'active' : ''}>
                    <div className="dot-container d-f ai-c jc-c">
                      <span className="dot" />
                    </div>
                    <a>{TRANSLATIONS.sidebar.plansTranslations}</a>
                  </li>
                </Link>
                <Link href="/admin/translations" passHref={true}>
                  <li className={adminTranslations ? 'active' : ''}>
                    <div className="dot-container d-f ai-c jc-c">
                      <span className="dot" />
                    </div>
                    <a>{TRANSLATIONS.sidebar.labelTranslations}</a>
                  </li>
                </Link>
                <Link href="/admin/users" passHref={true}>
                  <li className={adminUsers ? 'active' : ''}>
                    <div className="dot-container d-f ai-c jc-c">
                      <span className="dot" />
                    </div>
                    <a>{TRANSLATIONS.sidebar.usersManagement}</a>
                  </li>
                </Link>
                <Link href="/admin/returns" passHref={true}>
                  <li className={adminReturns ? 'active' : ''}>
                    <div className="dot-container d-f ai-c jc-c">
                      <span className="dot" />
                    </div>
                    <a>{TRANSLATIONS.sidebar.returnsManagement}</a>
                  </li>
                </Link>
                <Link href="/admin/bill" passHref={true}>
                  <li className={adminBills ? 'active' : ''}>
                    <div className="dot-container d-f ai-c jc-c">
                      <span className="dot" />
                    </div>
                    <a>{TRANSLATIONS.sidebar.billsAndCustomers}</a>
                  </li>
                </Link>
                <Link href="/admin/invoices" passHref={true}>
                  <li className={adminInvoices ? 'active' : ''}>
                    <div className="dot-container d-f ai-c jc-c">
                      <span className="dot" />
                    </div>
                    <a>{TRANSLATIONS.sidebar.invoicesManagement}</a>
                  </li>
                </Link>
              </ul>
            </div>
            <Link href="/" passHref={true}>
              <li className={router.pathname === '/' ? 'active' : ''}>
                <Icon type="home" style={{ fontSize: 22 }} />
                <a>{TRANSLATIONS.sidebar.home}</a>
              </li>
            </Link>
          </ul>
          <ul className="menu category">
            {!PERMISSIONS.IS_PRIVATE && (
              <>
                <Link
                  href="/product?availability=1&attributes=canale-Moda"
                  passHref={true}
                >
                  <li
                    className={
                      router.asPath ===
                      '/product?availability=1&attributes=canale-Moda'
                        ? 'active'
                        : ''
                    }
                  >
                    <Icon type="tag" style={{ fontSize: 22 }} />
                    <a>{TRANSLATIONS.sidebar.moda}</a>
                  </li>
                </Link>
                {(user?.countryCode !== 'IT' || user?.ignoreRestrinctions) && (
                  <Link
                    href="/product?availability=1&attributes=canale-sexy-shop"
                    passHref={true}
                  >
                    <li
                      className={
                        router.asPath ===
                        '/product?availability=1&attributes=canale-sexy-shop'
                          ? 'active'
                          : ''
                      }
                    >
                      <Icon type="tag" style={{ fontSize: 22 }} />
                      <a>{TRANSLATIONS.sidebar.sexyShop}</a>
                    </li>
                  </Link>
                )}
                <Link href="/order" passHref={true}>
                  <li className={router.pathname === '/order' ? 'active' : ''}>
                    <Icon type="book-open" style={{ fontSize: 22 }} />
                    <a>{TRANSLATIONS.sidebar.orderList}</a>
                  </li>
                </Link>
                <Link href="/order/return" passHref={true}>
                  <li
                    className={
                      router.pathname === '/order/return' ? 'active' : ''
                    }
                  >
                    <Icon type="refresh" style={{ fontSize: 22 }} />
                    <a>{TRANSLATIONS.sidebar.return}</a>
                  </li>
                </Link>
              </>
            )}
            {PERMISSIONS.IS_PRIVATE && (
              <>
                <div className={'title_section'}>
                  <h5>{TRANSLATIONS.sidebar.productCatalog}</h5>
                </div>
                <Link
                  href={`/channel/${catalogs[0]?._id}/edit/products?category=clothing`}
                  passHref={true}
                >
                  <li>
                    <InlineIcon icon="ph:t-shirt" style={{ fontSize: 24 }} />
                    <a>{TRANSLATIONS.header.subheader.clothing}</a>
                  </li>
                </Link>
                <Link
                  href={`/channel/${catalogs[0]?._id}/edit/products?category=accessories`}
                  passHref={true}
                >
                  <li>
                    <InlineIcon
                      icon="ph:watch"
                      style={{ fontSize: 24, fontWeight: 600 }}
                    />
                    <a>{TRANSLATIONS.header.subheader.accessories}</a>
                  </li>
                </Link>
                <Link
                  href={`/channel/${catalogs[0]?._id}/edit/products?category=bags`}
                  passHref={true}
                >
                  <li>
                    <InlineIcon
                      icon="clarity:shopping-bag-line"
                      style={{ fontSize: 24, fontWeight: '800' }}
                    />
                    <a>{TRANSLATIONS.header.subheader.bags}</a>
                  </li>
                </Link>
                <Link
                  href={`/channel/${catalogs[0]?._id}/edit/products?category=shoes`}
                  passHref={true}
                >
                  <li className={'shoes-list'}>
                    <InlineIcon
                      icon="tabler:shoe"
                      style={{ fontSize: 24, fontWeight: 600 }}
                    />
                    <a>{TRANSLATIONS.header.subheader.shoes}</a>
                  </li>
                </Link>
                <Link
                  href={`/channel/${catalogs[0]?._id}/edit/products?category=underwear`}
                  passHref={true}
                >
                  <li className={'underwear-list'}>
                    <InlineIcon
                      icon="icon-park-outline:clothes-pants-short"
                      style={{ fontSize: 24 }}
                    />
                    <a>{TRANSLATIONS.header.subheader.underwear}</a>
                  </li>
                </Link>
              </>
            )}
          </ul>
          <ul className="menu business mt-md">
            <div className={'title_section'}>
              <h5>{TRANSLATIONS.sidebar.resellerArea}</h5>
            </div>
            <div className="mt-md jc-sb d-f ai-c relative">
              {catalogs?.length > 0 && (
                <span className="title">
                  {TRANSLATIONS.sidebar.salesChannel}
                </span>
              )}
              {(catalogs?.length > 0 ||
                (PERMISSIONS.IS_ADMIN && catalogs?.length > 1)) && (
                <div className="absolute-icon">
                  <Tooltip
                    className={'left'}
                    text={TRANSLATIONS.sidebar.createList}
                  >
                    <Button
                      onClick={handleCreateList}
                      iconBefore="plus"
                      className={'icon-action-dark'}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
            {catalogs?.length < 1 && (
              <div className="button-first-list">
                <Button
                  onClick={handleCreateList}
                  label={TRANSLATIONS.sidebar.createList}
                  className={'action medium wide'}
                />
              </div>
            )}
            {catalogs.map((catalog, index) => (
              <>
                <div
                  className="catalog-li"
                  onClick={() => {
                    handleisActiveList(catalog?._id);
                  }}
                >
                  <Link
                    key={index}
                    href={`/channel/[id]/edit/[tab]`}
                    as={`/channel/${catalog._id}/edit/products`}
                    passHref={true}
                  >
                    <li
                      className={`${
                        id === catalog._id ? 'active' : ''
                      } import-list-li`}
                    >
                      <div className="dot-container d-f ai-c jc-c">
                        <Icon type="list" style={{ fontSize: 22 }} />
                      </div>
                      <a>{mapChannelService(catalog)}</a>
                    </li>
                  </Link>
                </div>
              </>
            ))}
          </ul>
          <ul className="menu resources mt-md">
            <div className={'title_section'}>
              <h5>{TRANSLATIONS.sidebar.resources}</h5>
            </div>
            <Link href="/shipping-fees" passHref={true}>
              <li
                className={router.pathname === '/shipping-fees' ? 'active' : ''}
              >
                <Icon type="truck" style={{ fontSize: 22 }} />
                <a>{TRANSLATIONS.sidebar.shipping}</a>
              </li>
            </Link>
            <Link href="/pricing" passHref={true}>
              <li className={router.pathname === '/pricing' ? 'active' : ''}>
                <Icon type="dollar-sign" style={{ fontSize: 22 }} />
                <a>{TRANSLATIONS.sidebar.pricing}</a>
              </li>
            </Link>
          </ul>
          <div className="container-aidify">
            <p className="title-aidify">{TRANSLATIONS.sidebar.aidifyText}</p>
            <Button className="aidify-button wide" onClick={goToAidify}>
              {TRANSLATIONS.sidebar.aidifyCta}
            </Button>
          </div>
        </div>
      </aside>
      <style jsx>{style}</style>
    </>
  );
}

export default Sidebar;
